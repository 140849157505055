import { string } from "prop-types";

// Helpers for setting / retrieving data from local session and storage


/**
 * Set Local Storage. Objects will be serialized as json.
 * 
 * @param string key
 * @param value any
 * @return string - json formatted value
 */
export function setLocalStorage(key: string, value: Record<string, any>) {
  const serializedValue = JSON.stringify(value);
  window.localStorage.setItem(key, serializedValue);
  return serializedValue;
}

/**
 * Retrieve value from Local Storage. Objects will be serialized as json.
 * 
 * @param string key
 * @return object
 */
export function getLocalStorage<T = {}>(key: string): T | null {
  const value = window.localStorage.getItem(key);
  return value ? JSON.parse(value) as T : null;
}

/**
 * Set Session Storage. Objects will be serialized as json.
 * 
 * @param string key
 * @param object value
 * @return string json formatted value
 */
export function setLocalSession(key: string, value: Record<string, any>) {
  const serializedValue = JSON.stringify(value);
  window.sessionStorage.setItem(key, serializedValue);
  return serializedValue;
}

/**
 * Get Session Storage. Objects will be serialized as json.
 * 
 * @param string key
 * @return object
 */
export function getLocalSession<T = {}>(key: string): T | null {
  const value = window.sessionStorage.getItem(key);
  return  value ? JSON.parse(value) as T : null;
}
