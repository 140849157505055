//  Name:           Validators
//
//  Description:    Functions used for validatiion
//
//  Credit:
//
//  Requires:
//
//  ============================================================================

/**
 * Returns true if the given JSX element matches the given component type.
 *
 * NOTE: This function only checks equality of `displayName` for performance and
 * to tolerate multiple minor versions of a component being included in one
 * application bundle.
 * @param element JSX element in question
 * @param ComponentType desired component type of element
 */
export function isElementOfType<P = {}>(
  element: any,
  ComponentType: React.ComponentType<P>
): element is React.ReactElement<P> {
  return (
    element != null &&
    element.type != null &&
    element.type.displayName != null &&
    element.type.displayName === ComponentType.displayName
  );
}

/** Returns whether the value is a function. Acts as a type guard. */
export function isFunction(value: any): value is Function {
  return typeof value === 'function';
}

/**
 * Determines if an element is given element or contains given element
 * @param element HTMLElment
 * @param testElement HTMLElement
 *
 * @returns {boolean}
 */
export function elementIsOrContains(
  element: HTMLElement,
  testElement: HTMLElement
) {
  return element === testElement || element.contains(testElement);
}

/**
 * Determine if the given value is truthy
 *
 * @param value any
 */
export function isTruthy(value: any) {
  return (
    value !== false && value !== undefined && value !== null && value !== ''
  );
}

/**
 * Determine if the given value is a number or string representing an integer
 *
 * @param value any
 */
export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

/**
 * Determine if the given value is an integer
 *
 * @param value any
 */
export function isInteger(value: any) {
  return Number.isInteger(value);
}

/** Returns whether `process.env.NODE_ENV` exists and equals `env`. */
export function isNodeEnv(env: string) {
  return (
    typeof process !== 'undefined' &&
    process.env &&
    process.env.NODE_ENV === env
  );
}
