import * as React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import DebounceLink from 'apollo-link-debounce';
import { onError } from 'apollo-link-error';
import { ApolloProvider } from '@apollo/react-hooks';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragment-types.json';

import config from './config';
import * as serviceWorker from './serviceWorker';
import { App } from './app';
import { getSessionToken } from './library/session';

import './index.scss';

const session = getSessionToken();

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Apollo Links
const debounceLink = new DebounceLink(100);
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const httpLink = new HttpLink({
  uri: config.api.apiBaseUrl,
  headers: {
    authorization: session ? `Bearer ${session.token}` : '',
  },
});

// Apollo Client
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, debounceLink, httpLink]),
  cache: new InMemoryCache({ fragmentMatcher }),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
