// Helpers for the user session

import * as React from 'react';
import { decode } from 'jsonwebtoken';
import { getLocalSession, getLocalStorage } from './storage';


export interface JWT {
  token: string;
}

export interface SessionToken {
  aud: string;
  exp: number;
  iat: Date;
  iss: string;
  sub: string;
}

const SESSION_KEY = 'session';

export function getSessionToken(): JWT | null {
  return getLocalStorage<JWT>(SESSION_KEY) || getLocalSession<JWT>(SESSION_KEY);
}

export function clearSessionToken(): void {
  window.localStorage.removeItem(SESSION_KEY);
  window.sessionStorage.removeItem(SESSION_KEY);
}

export function isTokenExpired(token: string) {
  const decodedToken = decode(token) as SessionToken | null;
  
  if (decodedToken === null) return true;
  return Date.now() > decodedToken.exp * 1000;
}

export function decodeToken(token: string): SessionToken {
  return decode(token) as SessionToken;
}

//
// React Context
//

export interface SessionContext {
  isLoggedIn: boolean,
  user?: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  }
}

export const SessionContext = React.createContext<SessionContext>({ isLoggedIn: false });