import * as React from 'react';
import { Redirect, RouteComponentProps } from '@reach/router';
import cn from 'classnames';

import { LoginForm } from '../../layouts/partials';
import { RouteContext } from './route-group';
import { isTokenExpired, getSessionToken, SessionContext } from '../../library';

export interface IRouteBaseProps {
  /**
   * Whether the user authorized to view this page.
   * Will throw a 401 error if false.
   */
  isAuthorized?: boolean;

  /**
   * If privacy check fails, redirect to this url, rather than showing
   * login form
   */
  fallbackUrl?: string;

  /**
   * Whether a user must be logged in to view the page.
   * Will redirect to login page if false.
   */
  isPrivate?: boolean;

  /** The title of the page */
  title?: string;
}

interface IRouteProps extends IRouteBaseProps, RouteComponentProps {
  /** Component to render when route is matched */
  component: React.ElementType;
}

export const Route: React.FunctionComponent<IRouteProps> = props => {
  // Setup routeGroup context
  const context = React.useContext(RouteContext);
  const mergedProps = { ...context, ...props };
  const sessionContext = React.useContext(SessionContext);

  const {
    component: Component,
    default: isDefault,
    fallbackUrl,
    isAuthorized,
    isPrivate,
    location,
    navigate,
    path,
    uri,
    title,
    ...passProps
  } = mergedProps;

  if (isPrivate && !sessionContext.isLoggedIn) {
    if (fallbackUrl) {
      return <Redirect to={fallbackUrl} from={uri} noThrow />;
    }
    return (
      <section className="section">
        <div className="container">
          <h5 className="title is-5">Sign In To Continue </h5>
          <div className={cn('box')}>
            <LoginForm />
          </div>
        </div>
      </section>
    );
  }

  if (!isAuthorized) {
    return <div>You are not authorized to view this page.</div>;
  }

  return <Component {...passProps} />;
};
