import * as React from 'react';
import { RouteComponentProps, Link } from '@reach/router';
import cn from 'classnames';
import { SessionContext } from '../../library';

export interface HomePageProps extends RouteComponentProps {
  isLoggedIn: boolean;
}

export const HomePage: React.FunctionComponent<HomePageProps> = props => {
  const sessionContext = React.useContext(SessionContext);

  return (
    <div>
      <section className="section">
        <div className="container">
          {sessionContext.user && (
            <h5 className="title is-5">
              Welcome, {sessionContext.user.firstName}{' '}
              {sessionContext.user.lastName}. You Are Logged In!
            </h5>
          )}
          <p>
            <Link to="/recipes" className="button is-primary">
              My Recipes
            </Link>
          </p>
        </div>
      </section>
    </div>
  );
};

HomePage.defaultProps = {
  isLoggedIn: false,
};
