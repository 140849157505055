export enum Measurement {
  // Volume
  TEASPOON,
  TABLESPOON,
  DROP,
  FLUID_OUNCE,
  CUP,
  PINT,
  GALLON,
  MILILITER,
  LITER,
  // Weight
  OUNCE_WEIGHT,
  POUND,
  GRAM,
  // Other
  EACH,
  PERCENT,
  PIECE,
  RECIPE,
  AS_NEEDED,
}

export const measurements = [
  { id: 'TEASPOON', label: 'Teaspoon', abrrev: 'tsp' },
  { id: 'TABLESPOON', label: 'Tablespoon', abrrev: 'tbsp' },
  { id: 'DROP', label: 'Drop', abrrev: 'drop' },
  { id: 'FLUID_OUNCE', label: 'Fluid Ounce', abrrev: 'oz' },
  { id: 'CUP', label: 'Cup', abrrev: 'c' },
  { id: 'PINT', label: 'Pint', abrrev: 'pint' },
  { id: 'GALLON', label: 'Gallon', abrrev: 'gal' },
  { id: 'MILILITER', label: 'Milileter', abrrev: 'ml' },
  { id: 'LITER', label: 'liter', abrrev: 'l' },
  { id: 'OUNCE_WEIGHT', label: 'Ounce', abrrev: 'oz' },
  { id: 'POUND', label: 'Pound', abrrev: 'lb' },
  { id: 'GRAM', label: 'Gram', abrrev: 'g' },
  { id: 'EACH', label: 'Each', abrrev: 'each' },
  { id: 'PERCENT', label: 'Percent', abrrev: '%' },
  { id: 'PIECE', label: 'Piece', abrrev: 'piece' },
  { id: 'AS_NEEDED', label: 'As Needed', abrrev: 'as needed' },
];
