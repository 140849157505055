import * as React from 'react';

import {
  IngredientListItem,
  IngredientItem,
  IngredientGroup as IngredientGroupType,
} from '../../types/server-types';
import { Ingredient } from './ingredient';
import { IngredientGroup } from './ingredient-group';
import { safeInvoke } from '../../library';

interface IngredientListProps {
  ingredients: IngredientListItem[];
  onUpdateGroup?: (group: IngredientGroupType) => void;
  onUpdateIngredient?: (ingredient: IngredientItem) => void;
  onAddIngredient?: () => void;
  onAddGroup?: () => void;
  onRemoveIngredient?: (ingredient: IngredientItem) => void;
  onRemoveGroup?: (group: IngredientGroupType) => void;
  children?: never;
}

export const IngredientList = (props: IngredientListProps) => {
  //
  // Handlers
  //

  function handleIngredientUpdate(ing: IngredientItem) {
    safeInvoke(props.onUpdateIngredient, ing);
  }

  function handleGroupUpdate(group: IngredientGroupType) {
    safeInvoke(props.onUpdateGroup, group);
  }

  function handleIngredientAdd() {
    safeInvoke(props.onAddIngredient);
  }

  function handleGroupAdd() {
    safeInvoke(props.onAddGroup);
  }

  function handleIngredientRemove(ingredient: IngredientItem) {
    safeInvoke(props.onRemoveIngredient, ingredient);
  }

  function handleGroupRemove(group: IngredientGroupType) {
    safeInvoke(props.onRemoveGroup, group);
  }

  //
  // Render
  //

  return (
    <div className="ingredient-list">
      {props.ingredients.map(node => {
        switch (node.__typename) {
          case 'IngredientItem':
            return (
              <Ingredient
                key={node.id}
                {...node}
                onUpdate={handleIngredientUpdate}
                onRemove={handleIngredientRemove}
              />
            );
          case 'IngredientGroup':
            return (
              <IngredientGroup
                key={node.id}
                {...node}
                onIngredientUpdate={handleIngredientUpdate}
                onUpdate={handleGroupUpdate}
              />
            );
        }
      })}
      <div className="buttons">
        <button className="button" onClick={handleIngredientAdd}>
          Add
        </button>
        <button className="button" onClick={handleGroupAdd}>
          Add Group
        </button>
      </div>
    </div>
  );
};
