import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { IRouteBaseProps } from './route';

interface IRouteGroupProps extends IRouteBaseProps, RouteComponentProps {
}

const defaultContext = {
  isAuthorized: true,
  isPrivate: false,
  title: '',
}

export const RouteContext = React.createContext<IRouteBaseProps>(defaultContext);

// 
// Route Group
// 
export const RouteGroup: React.FunctionComponent<IRouteGroupProps> = (props) => {
  const { children, location, navigate, path, uri, ...contextProps } = props;
  return (
    <RouteContext.Provider value={{ ...defaultContext, ...contextProps }}>
      {props.children}
    </RouteContext.Provider>
  );
}

RouteGroup.displayName = 'RouteGroup';

