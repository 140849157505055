import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Router, navigate, Link } from '@reach/router';

import { Route, RouteGroup } from './components';
import { SessionContext } from './library/session';
import {
  HomePage,
  RecipeIndexPage,
  RecipePage,
  CollectionIndexPage,
  CollectionPage,
} from './layouts/pages';
import { decodeToken, getSessionToken } from './library';

const userQuery = gql`
  query FetchUser($id: ID) {
    user(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const App: React.FunctionComponent = props => {
  const userJWT = getSessionToken();
  const userId = userJWT ? decodeToken(userJWT.token).sub : '';

  const { loading, error, data } = useQuery(userQuery, {
    variables: {
      id: userId,
    },
  });
  return (
    <SessionContext.Provider
      value={{
        isLoggedIn: !!data,
        user: data ? data.user : undefined,
      }}
    >
      <div className="app">
        <div className="container">
          <div className="navbar">
            <div className="navbar-band">
              <Link to="/" className="navbar-item">
                <span className="title is-4">Recipeasy</span>
              </Link>
            </div>
            <div className="navbar-menu">
              <div className="navbar-end">
                <Link to="/recipes" className="navbar-item">
                  Recipes
                </Link>
                <Link to="/collections" className="navbar-item">
                  Collections
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* 
            Setting primary to false removes the 'auto-focus' feature of Reach
            Router, but right now it causes the browser to jump around depending
            on the location of the component and scroll position.

            For now, we're forgoing the accessibility enhancement for a
            more predictable viewport position
          */}
        <Router primary={false} className="page">
          <Route path="/" component={HomePage} title="Login" isPrivate />
          <Route
            path="/recipes"
            component={RecipeIndexPage}
            title="My Recipes"
            isPrivate
          />
          <Route
            path="/recipes/:recipeId"
            component={RecipePage}
            title="My Recipes"
            isPrivate
          />
          <RouteGroup path="/collections" isPrivate>
            <Route
              path="/"
              component={CollectionIndexPage}
              title="Collctions"
            />
            <Route
              path=":collectionId/*"
              component={CollectionPage}
              title="Collection"
            />
          </RouteGroup>
        </Router>
      </div>
    </SessionContext.Provider>
  );
};
