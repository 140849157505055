import * as React from 'react';

import {
  IngredientGroup as IngredientGroupType,
  IngredientItem,
} from '../../types/server-types';
import { Ingredient } from './ingredient';
import { safeInvoke } from '../../library';

interface IngredientGroupProps extends IngredientGroupType {
  onUpdate?: (group: IngredientGroupType) => void;
  onIngredientUpdate?: (ingredient: IngredientItem) => void;
}

export const IngredientGroup = (props: IngredientGroupProps) => {
  const handleIngredientUpdate = (ingredient: IngredientItem) => {
    safeInvoke(props.onIngredientUpdate, ingredient);
  };
  const handleHeadingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const heading = e.currentTarget.value;
    safeInvoke(props.onUpdate, { ...props, heading });
  };

  return (
    <div>
      <div>
        <input
          className="input"
          value={props.heading ? props.heading : ''}
          placeholder="Untitled Heading"
          onChange={handleHeadingChange}
        />
      </div>
      {props.nodes.map(node => (
        <Ingredient key={node.id} {...node} onUpdate={handleIngredientUpdate} />
      ))}
    </div>
  );
};
