import * as React from 'react';
import { userApi } from '../../api/user';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { MutationLoginArgs } from '../../types/server-types';
import { setLocalStorage, JWT } from '../../library';

const loginUserMutation = gql`
  mutation LoginUser($input: LoginUserInput) {
    login(input: $input) {
      token
    }
  }
`;

interface LoginResponse {
  login: {
    token: JWT;
  };
}

export const LoginForm: React.FunctionComponent = () => {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [login] = useMutation<LoginResponse, MutationLoginArgs>(
    loginUserMutation
  );

  const updateUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value);
  };

  const updatePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    login({
      variables: { input: { email: username, password } },
    }).then(resp => {
      if (resp.data && resp.data.login.token) {
        setLocalStorage('session', resp.data.login);
        window.location.reload();
      } else {
        // TODO: show error
      }
    });
  };

  return (
    <div>
      <form onSubmit={submitForm}>
        <div className="field">
          <label className="label" htmlFor="username">
            Username
          </label>
          <input
            className="input"
            type="text"
            name="username"
            value={username}
            onChange={updateUsername}
          />
        </div>
        <div className="field">
          <label className="label" htmlFor="password">
            Password
          </label>
          <input
            className="input"
            type="password"
            name="password"
            value={password}
            onChange={updatePassword}
          />
        </div>
        <button className="button is-primary" type="submit">
          Login
        </button>
      </form>
    </div>
  );
};
