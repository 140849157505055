import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import cn from 'classnames';
import { SessionContext } from '../../library';
import { Recipe, MutationCreateRecipeArgs } from '../../types/server-types';

export const recipeListQuery = gql`
  {
    recipes {
      id
      title
      instructions
    }
  }
`;

const createRecipeMutation = gql`
  mutation createRecipe($input: CreateRecipeInput) {
    createRecipe(input: $input) {
      id
      title
      instructions
    }
  }
`;

export const RecipeIndexPage: React.FunctionComponent = props => {
  const { loading, error, data } = useQuery<{ recipes: Recipe[] }>(
    recipeListQuery
  );
  const [createRecipe] = useMutation<
    { createRecipe: Recipe },
    MutationCreateRecipeArgs
  >(createRecipeMutation, {
    update(cache, response) {
      const recipeResponse = cache.readQuery<{ recipes: Recipe[] }>({
        query: recipeListQuery,
      });
      if (!recipeResponse) return;

      cache.writeQuery({
        query: recipeListQuery,
        data: {
          recipes: [...recipeResponse.recipes, response.data.createRecipe],
        },
      });
    },
  });

  const handleCreateRecipe = () => {
    createRecipe().then(recipe => {
      if (!recipe.data) return;
      navigate('/recipes/' + recipe.data.createRecipe.id);
    });
  };

  const renderRecipeList = (recipes: Recipe[]) => {
    if (!recipes) return null;
    return (
      <ul>
        {recipes.map(recipe => {
          return (
            <li key={recipe.id}>
              <Link to={`/recipes/${recipe.id}`}>
                <span className="title is-3">
                  {recipe.title ? recipe.title : 'Untitled Recipe'}
                </span>
              </Link>
              <p>{recipe.instructions}</p>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="title is-5">My Tasty Recipes</h2>
            </div>
            <div className="column is-narrow">
              <button
                className="button is-primary"
                onClick={handleCreateRecipe}
              >
                New Recipe
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          {loading && 'Loading...'}
          {error && 'Dang... we couldnt load your recipes'}
          {!!data && renderRecipeList(data.recipes)}
        </div>
      </section>
    </div>
  );
};

RecipeIndexPage.defaultProps = {};
