import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import cn from 'classnames';
import { findIndex } from 'lodash';

import {
  IngredientItem,
  UpdateIngredientInput,
  MutationUpdateIngredientArgs,
  Recipe,
  MutationAddIngredientArgs,
  MutationRemoveIngredientArgs,
  MutationUpdateRecipeArgs,
  MutationDeleteRecipeArgs,
} from '../../types/server-types';
import { IngredientList } from '../../components/ingredient';
import { measurements } from '../../library';
import { recipeListQuery } from './recipes';

const ingredientDataFragment = gql`
  fragment IngredientData on IngredientItem {
    id
    order
    node {
      name
      measurement
      quantity
      note
    }
  }
`;

const recipeQuery = gql`
  ${ingredientDataFragment}
  query fetchRecipe($id: ID!) {
    recipe(id: $id) {
      id
      title
      instructions
      ingredientList {
        nodes {
          ... on IngredientItem {
            ...IngredientData
          }
          ... on IngredientGroup {
            id
            order
            heading
            nodes {
              ...IngredientData
            }
          }
        }
      }
    }
  }
`;

const updateIngredientMutation = gql`
  ${ingredientDataFragment}
  mutation updateIngredient($input: UpdateIngredientInput) {
    updateIngredient(input: $input) {
      ...IngredientData
    }
  }
`;

const addIngredientMutation = gql`
  ${ingredientDataFragment}
  mutation addIngredient($input: AddIngredientInput) {
    addIngredient(input: $input) {
      ...IngredientData
    }
  }
`;

const removeIngredientMutation = gql`
  ${ingredientDataFragment}
  mutation removeIngredient($input: RemoveIngredientInput) {
    removeIngredient(input: $input) {
      ...IngredientData
    }
  }
`;

const updateRecipeMutation = gql`
  mutation updateRecipe($input: UpdateRecipeInput) {
    updateRecipe(input: $input) {
      id
      title
      instructions
    }
  }
`;

const deleteRecipeMutation = gql`
  mutation deleteRecipe($input: DeleteRecipeInput) {
    deleteRecipe(input: $input) {
      id
    }
  }
`;

interface UpdateRecipeResponse {
  updateRecipe: Partial<Recipe>;
}

interface UpdateIngredientResponse {
  updateIngredient: Partial<IngredientItem>;
}

interface AddIngredientResponse {
  addIngredient: Partial<IngredientItem>;
}

interface RemoveIngredientResponse {
  removeIngredient: Partial<IngredientItem>;
}

interface RecipePageProps {
  recipeId: string;
}

export const RecipePage: React.FunctionComponent<RecipePageProps> = props => {
  const [updateRecipe] = useMutation<
    UpdateRecipeResponse,
    MutationUpdateRecipeArgs
  >(updateRecipeMutation, {
    context: {
      debounceTimeout: 200,
      debounceKey: 'recipe:' + props.recipeId,
    },
  });

  const [deleteRecipe] = useMutation<
    UpdateRecipeResponse,
    MutationDeleteRecipeArgs
  >(deleteRecipeMutation, {
    update(cache, response) {
      let recipeResponse;
      try {
        recipeResponse = cache.readQuery<{ recipes: Recipe[] }>({
          query: recipeListQuery,
        });
      } catch (e) {
        return;
      }
      if (!recipeResponse) return;
      cache.writeQuery({
        query: recipeListQuery,
        data: {
          recipes: recipeResponse.recipes.filter(
            e => e.id !== response.data.deleteRecipe.id
          ),
        },
      });
    },
  });

  const [updateIngredient] = useMutation<
    UpdateIngredientResponse,
    MutationUpdateIngredientArgs
  >(updateIngredientMutation, {
    context: {
      debounceTimeout: 200,
    },
  });

  const [addIngredient] = useMutation<
    AddIngredientResponse,
    MutationAddIngredientArgs
  >(addIngredientMutation, {
    update(cache, response) {
      // get current recipe in cache
      const recipeResponse = cache.readQuery<{ recipe: Recipe }>({
        query: recipeQuery,
        variables: { id: props.recipeId },
      });
      if (!recipeResponse) return;

      // insert new ingredient
      recipeResponse.recipe.ingredientList.nodes.splice(
        response.data.addIngredient.order,
        0,
        response.data.addIngredient
      );

      // update cache
      cache.writeQuery({
        query: recipeQuery,
        data: { recipe: recipeResponse.recipe },
      });
    },
  });

  const [removeIngredient] = useMutation<
    RemoveIngredientResponse,
    MutationRemoveIngredientArgs
  >(removeIngredientMutation, {
    update(cache, response) {
      // get current recipe in cache
      const recipeResponse = cache.readQuery<{ recipe: Recipe }>({
        query: recipeQuery,
        variables: { id: props.recipeId },
      });
      if (!recipeResponse) return;

      // remove ingredient
      const index = findIndex(recipeResponse.recipe.ingredientList.nodes, [
        'id',
        response.data.removeIngredient.id,
      ]);
      if (!index) return;
      recipeResponse.recipe.ingredientList.nodes.splice(index, 1);

      // update cache
      cache.writeQuery({
        query: recipeQuery,
        data: { recipe: recipeResponse.recipe },
      });
    },
  });

  const { loading, error, data } = useQuery<{ recipe: Recipe }>(recipeQuery, {
    variables: { id: props.recipeId },
  });

  const handleIngredientUpdate = (ing: IngredientItem) => {
    if (!data) return;
    const updateProps = {
      recipeId: data.recipe.id,
      ingredientId: ing.id,
      order: ing.order,
      ingredient: {
        name: ing.node.name,
        quantity: ing.node.quantity,
        note: ing.node.note,
        measurement: ing.node.measurement,
      },
    };

    updateIngredient({
      variables: {
        input: updateProps,
      },
      context: {
        debounceKey: `${data.recipe.id}:${ing.id}`,
        debounceTimeout: 500,
      },
      optimisticResponse: {
        updateIngredient: {
          __typename: 'IngredientItem',
          id: ing.id,
          order: ing.order,
          node: { ...updateProps.ingredient, __typename: 'Ingredient' },
        },
      },
    });
  };

  const handleAddIngredient = () => {
    if (!data) return;
    addIngredient({
      variables: {
        input: { recipeId: data.recipe.id, ingredient: {} },
      },
    });
  };

  const handleRemoveIngredient = (ing: IngredientItem) => {
    if (!data) return;
    removeIngredient({
      variables: {
        input: { recipeId: data.recipe.id, ingredientId: ing.id },
      },
    });
  };

  const handleTitleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!data) return;

    const { ingredientList, __typename, ...oldProps } = data.recipe;
    updateRecipe({
      variables: {
        input: { ...oldProps, title: value },
      },
      optimisticResponse: {
        updateRecipe: {
          __typename: 'Recipe',
          ...oldProps,
          title: value,
        },
      },
    });
  };

  const handleInstructionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    if (!data) return;

    const { ingredientList, __typename, ...oldProps } = data.recipe;
    updateRecipe({
      variables: {
        input: { ...oldProps, instructions: value },
      },
      optimisticResponse: {
        updateRecipe: {
          __typename: 'Recipe',
          ...oldProps,
          instructions: value,
        },
      },
    });
  };

  const handleDelete = () => {
    deleteRecipe({ variables: { input: { id: props.recipeId } } }).then(() => {
      navigate('/recipes');
    });
  };

  const renderRecipe = (recipe: Recipe) => {
    if (!recipe) return null;
    return (
      <div>
        <div className="container">
          <div className="columns">
            <div className="column">
              <p>
                <Link to="/recipes">Back</Link>
              </p>
            </div>
            <div className="column is-narrow">
              <button className="button is-danger" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <input
              className={cn('input title is-2')}
              value={recipe.title ? recipe.title : ''}
              placeholder={'Untitled Recipe'}
              onChange={handleTitleUpdate}
            />
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="card">
                  <div className="card-header">
                    <div className="card-header-title">Ingredients</div>
                  </div>
                  <div className="card-content">
                    <IngredientList
                      ingredients={recipe.ingredientList.nodes}
                      onUpdateIngredient={handleIngredientUpdate}
                      onAddIngredient={handleAddIngredient}
                      onRemoveIngredient={handleRemoveIngredient}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h5 className="title is-5">Instructions</h5>
                <textarea
                  className={cn('textarea')}
                  value={recipe.instructions}
                  onChange={handleInstructionChange}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  return (
    <div>
      {loading && 'Loading...'}
      {error && 'Dang... we couldnt load your recipes'}
      {!!data && renderRecipe(data.recipe)}
    </div>
  );
};

RecipePage.defaultProps = {};
