import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RecipeCollection, Recipe } from '../../types/server-types';
import { Link, navigate } from '@reach/router';

export const collectionListQuery = gql`
  {
    recipeCollections {
      id
      name
    }
  }
`;

const createCollectionMutation = gql`
  mutation createRecipeCollection($input: CreateRecipeCollectionInput) {
    createRecipeCollection(input: $input) {
      id
      name
    }
  }
`;

export const CollectionIndexPage: React.FunctionComponent = props => {
  const { loading, error, data } = useQuery<{
    recipeCollections: RecipeCollection[];
  }>(collectionListQuery);

  const [createCollection] = useMutation(createCollectionMutation, {
    update(cache, response) {
      const collectionResponse = cache.readQuery<{
        recipeCollections: RecipeCollection[];
      }>({
        query: collectionListQuery,
      });
      if (!collectionResponse) return;

      cache.writeQuery({
        query: collectionListQuery,
        data: {
          recipeCollections: [
            ...collectionResponse.recipeCollections,
            response.data.createRecipeCollection,
          ],
        },
      });
    },
  });

  //
  // Handlers
  //

  function handleCreateNewCollection() {
    createCollection().then(resp => {
      navigate(
        `/collections/${resp.data.createRecipeCollection.id}/untitled-recipe`
      );
    });
  }

  //
  // Render
  //

  function renderCollectionList(collections: RecipeCollection[]) {
    return collections.map(collection => (
      <div key={collection.id}>
        <Link to={`/collections/${collection.id}/${collection.name}`}>
          <span className="title is-3">
            {collection.name ? collection.name : 'Untitled Collection'}
          </span>
        </Link>
      </div>
    ));
  }

  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h2 className="title is-5">My Collections</h2>
            </div>
            <div className="column is-narrow">
              <button
                className="button is-primary"
                onClick={handleCreateNewCollection}
              >
                New Collection
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          {loading && 'Loading...'}
          {error && 'Dang... we couldnt load your recipes'}
          {!!data && renderCollectionList(data.recipeCollections)}
        </div>
      </section>
    </div>
  );
};
