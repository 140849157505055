import * as React from 'react';

import { measurements, safeInvoke } from '../../library';

import {
  IngredientItem,
  Measurement as MeasurementType,
} from '../../types/server-types';

interface IngredientProps extends IngredientItem {
  onUpdate?: (ingredient: IngredientItem) => void;
  onRemove?: (ingredient: IngredientItem) => void;
}

export const Ingredient = (props: IngredientProps) => {
  const { onUpdate, onRemove, ...ingredientProps } = props;

  const handleQuantityUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = parseInt(e.currentTarget.value);
    const node = { ...ingredientProps.node, quantity };
    safeInvoke(onUpdate, { ...ingredientProps, node });
  };

  const handleMeasurementUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const measurement = e.currentTarget.value as MeasurementType;
    const node = { ...ingredientProps.node, measurement };
    safeInvoke(onUpdate, { ...ingredientProps, node });
  };

  const handleNametUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    const node = { ...ingredientProps.node, name };
    safeInvoke(onUpdate, { ...ingredientProps, node });
  };

  const handleNoteUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const note = e.currentTarget.value;
    const node = { ...ingredientProps.node, note };
    safeInvoke(onUpdate, { ...ingredientProps, node });
  };
  const handleRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    const note = e.currentTarget.value;
    const node = { ...ingredientProps.node, note };
    safeInvoke(onRemove, ingredientProps);
  };

  return (
    <div className="field is-grouped">
      <div className="control">
        <input
          className="input"
          type="number"
          value={props.node.quantity}
          onChange={handleQuantityUpdate}
        />
      </div>
      <div className="control">
        <div className="select">
          <select
            name="measurement"
            id=""
            value={props.node.measurement}
            onChange={handleMeasurementUpdate}
          >
            {measurements.map(measurement => {
              return (
                <option key={measurement.id} value={measurement.id}>
                  {measurement.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="control">
        <input
          type="text"
          className="input"
          value={props.node.name}
          onChange={handleNametUpdate}
        />
      </div>
      <div className="control">
        <input
          type="text"
          className="input"
          value={props.node.note}
          onChange={handleNoteUpdate}
        />
      </div>
      <div className="control">
        <button
          className="button"
          value={props.node.note}
          onClick={handleRemove}
        >
          X
        </button>
      </div>
    </div>
  );
};
