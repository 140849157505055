import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps, Link, navigate } from '@reach/router';
import cn from 'classnames';

import {
  Recipe,
  RecipeCollection,
  MutationUpdateRecipeCollectionArgs,
  MutationDeleteRecipeCollectionArgs,
  RecipeCollectionItem,
  RemoveRecipesFromCollectionPayload,
  MutationRemoveRecipesFromCollectionArgs,
  MutationAddRecipesToCollectionArgs,
  AddRecipesToCollectionPayload,
} from '../../types/server-types';
import { collectionListQuery } from './collection-index';
import { recipeListQuery } from './recipes';

interface CollectionPageProps {
  collectionId: string;
}

const ingredientDataFragment = gql`
  fragment IngredientData on IngredientItem {
    id
    order
    node {
      name
      measurement
      quantity
      note
    }
  }
`;

const collectionQuery = gql`
  query fetchCollection($id: ID!) {
    recipeCollection(id: $id) {
      id
      name
      nodes {
        order
        recipe {
          id
          title
        }
      }
    }
  }
`;

const updateCollectionMutation = gql`
  mutation updateCollection($input: UpdateRecipeCollectionInput) {
    updateRecipeCollection(input: $input) {
      id
      name
    }
  }
`;

interface updateCollectionResponse {
  updateRecipeCollection: Partial<RecipeCollection>;
}

const deleteCollectionMutation = gql`
  mutation deleteRecipeCollection($input: DeleteRecipeCollectionInput!) {
    deleteRecipeCollection(input: $input) {
      id
    }
  }
`;

interface deleteCollectionRespoonse {
  delete: Partial<RecipeCollection>;
}

const removeRecipesMutation = gql`
  mutation removeRecipe($input: RemoveRecipesFromCollectionInput!) {
    removeRecipesFromCollection(input: $input) {
      recipeCollection {
        id
        nodes {
          order
          recipe {
            id
            title
          }
        }
      }
      removedRecipes
      rejectedRecipes
    }
  }
`;

interface removeRecipesResponse {
  removeRecipesFromCollection: RemoveRecipesFromCollectionPayload;
}

const addRecipesMutation = gql`
  mutation addRecipes($input: AddRecipesToCollectionInput!) {
    addRecipesToCollection(input: $input) {
      recipeCollection {
        id
        nodes {
          order
          recipe {
            id
            title
          }
        }
      }
      addedRecipes
      rejectedRecipes
    }
  }
`;

interface addRecipesResponse {
  addRecipesToCollection: AddRecipesToCollectionPayload;
}

export const CollectionPage: React.FunctionComponent<CollectionPageProps> = props => {
  const { loading, error, data } = useQuery<{
    recipeCollection: RecipeCollection;
  }>(collectionQuery, {
    variables: { id: props.collectionId },
  });

  const recipesQuery = useQuery<{
    recipes: Recipe[];
  }>(recipeListQuery);

  const [updateCollection] = useMutation<
    updateCollectionResponse,
    MutationUpdateRecipeCollectionArgs
  >(updateCollectionMutation, {
    context: {
      debounceTimeout: 200,
      debounceKey: 'collection:' + props.collectionId,
    },
  });

  const [deleteCollection] = useMutation<
    deleteCollectionRespoonse,
    MutationDeleteRecipeCollectionArgs
  >(deleteCollectionMutation, {
    update(cache, response) {
      let collectionResponse;
      try {
        collectionResponse = cache.readQuery<{
          recipeCollections: RecipeCollection[];
        }>({
          query: collectionListQuery,
        });
      } catch (e) {
        return;
      }
      if (!collectionResponse) return;
      cache.writeQuery({
        query: collectionListQuery,
        data: {
          recipeCollections: collectionResponse.recipeCollections.filter(
            e => e.id !== response.data.deleteRecipeCollection.id
          ),
        },
      });
    },
  });

  const [removeRecipes] = useMutation<
    removeRecipesResponse,
    MutationRemoveRecipesFromCollectionArgs
  >(removeRecipesMutation);

  const [addRecipes] = useMutation<
    addRecipesResponse,
    MutationAddRecipesToCollectionArgs
  >(addRecipesMutation);

  //
  // Handlers
  //

  function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    if (!data) return;

    const { __typename, ...oldProps } = data.recipeCollection;

    updateCollection({
      variables: {
        input: {
          id: props.collectionId,
          name: value,
        },
      },
      optimisticResponse: {
        updateRecipeCollection: {
          __typename: 'RecipeCollection',
          ...oldProps,
          name: value,
        },
      },
    });
  }

  function handleDeleteCollection() {
    deleteCollection({ variables: { input: { id: props.collectionId } } }).then(
      () => {
        navigate('/collections');
      }
    );
  }

  function handleRemoveRecipe(ids: string[]) {
    const updatedNodes = !data
      ? []
      : data.recipeCollection.nodes.filter(node => {
          return ids.indexOf(node.recipe.id) == -1;
        });

    removeRecipes({
      variables: {
        input: {
          recipeCollectionId: props.collectionId,
          recipes: ids,
        },
      },
      optimisticResponse: {
        removeRecipesFromCollection: {
          recipeCollection: {
            id: props.collectionId,
            nodes: updatedNodes,
            __typename: 'RecipeCollection',
          },
          removedRecipes: ids,
          rejectedRecipes: [],
          __typename: 'RemoveRecipesFromCollectionPayload',
        },
      },
    });
  }

  function handleAddRecipe(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = e.currentTarget.value;
    const updatedNodes = !data ? [] : [...data.recipeCollection.nodes];
    const alreadyExits =
      updatedNodes.findIndex(node => node.recipe.id === value) > -1;

    if (!alreadyExits) {
      const recipe = !recipesQuery.data
        ? undefined
        : recipesQuery.data.recipes.find(rcp => {
            return rcp.id === value;
          });
      const newNode: RecipeCollectionItem | null = recipe
        ? {
            order: data ? data.recipeCollection.nodes.length : 0,
            recipe,
            __typename: 'RecipeCollectionItem',
          }
        : null;

      if (newNode) {
        updatedNodes.push(newNode);
      }
    }

    addRecipes({
      variables: {
        input: {
          recipeCollectionId: props.collectionId,
          recipes: [value],
        },
      },
      optimisticResponse: {
        addRecipesToCollection: {
          recipeCollection: {
            id: props.collectionId,
            nodes: updatedNodes,
            __typename: 'RecipeCollection',
          },
          addedRecipes: [value],
          rejectedRecipes: [],
          __typename: 'AddRecipesToCollectionPayload',
        },
      },
    });
  }

  //
  // Render
  //

  function renderCollectionItem(item: RecipeCollectionItem) {
    return (
      <div key={item.recipe.id} className="column is-4">
        <div className="card">
          <div className="card-content">
            <Link to={`/recipes/${item.recipe.id}`}>
              <h2 className="title is-5">{item.recipe.title}</h2>
            </Link>
          </div>
          <footer className="card-footer">
            <Link
              to={`/recipes/${item.recipe.id}`}
              className="card-footer-item"
              children="View"
            />
            <a
              href="#"
              className="card-footer-item"
              onClick={() => handleRemoveRecipe([item.recipe.id])}
              children="Remove"
            />
          </footer>
        </div>
      </div>
    );
  }

  const renderCollection = (collection: RecipeCollection) => {
    if (!collection) return null;
    return (
      <div>
        <div className="container">
          <div className="columns">
            <div className="column">
              <p>
                <Link to="/collections">Back</Link>
              </p>
            </div>
            <div className="column is-narrow">
              <button
                className="button is-danger"
                onClick={handleDeleteCollection}
                children={'Delete'}
              />
            </div>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <h6 className="title is-6">Collection</h6>
            <input
              className={cn('input title is-2')}
              value={collection.name ? collection.name : ''}
              placeholder={'Untitled Collection'}
              onChange={handleNameChange}
            />
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              {collection.nodes.map(renderCollectionItem)}
            </div>
            <div className="columns is-vcentered">
              <div className="column is-narrow">
                <span className="title is-6">Add Recipe:</span>
              </div>
              <div className="column">
                <div className="select">
                  <select onChange={handleAddRecipe}>
                    {recipesQuery.data
                      ? recipesQuery.data.recipes.map(recipe => (
                          <option key={recipe.id} value={recipe.id}>
                            {recipe.title
                              ? recipe.title
                              : 'Untitled Collection'}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  return (
    <div>
      {loading && 'Loading...'}
      {error && 'Dang... we couldnt load your collection'}
      {!!data && renderCollection(data.recipeCollection)}
    </div>
  );
};

CollectionPage.defaultProps = {};
